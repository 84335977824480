<template>
  <div class="activity_boxs">
    <header class="activity_head">
      <img src="@/assets/activity/activity.png" class="img_css" />
    </header>
    <main class="activity_main">
        <div class="details_head">
            <img src="@/assets/select_idx.png" class="select_idx">您的位置 > 文创活动 > 文章详情
        </div>
        <div class="details_box">
            <div class="details_title">{{info.value.name}}</div>
            <div class="details_introduce">
<!--                <div class="details_text">木木美术馆  北京隆福寺</div>-->
                <div class="details_time">{{info.value.beginTime}}</div>
            </div>
            <img  :src="imgUrl+info.value.coverPicsList"  class="details_png">
<!--            <div class="details_title1">意大利文艺复兴纸上绘画：一次与中国的对话-->
<!--                <br/>2021年9月3日—2022年2月20日</div>-->
         </div>
<!--         <div class="time_line">-->
<!--            <el-timeline>-->
<!--                <el-timeline-item-->
<!--                    v-for="(activity, index) in activities"-->
<!--                    :key="index"-->
<!--                    :icon="activity.icon"-->
<!--                    :type="activity.type"-->
<!--                    :color="activity.color"-->
<!--                    :size="activity.size"-->
<!--                    :hollow="activity.hollow"-->
<!--                    :timestamp="activity.timestamp"-->
<!--                    >-->
<!--                    <div class="item_line_text">{{ activity.content }}</div>-->
<!--                </el-timeline-item>-->
<!--            </el-timeline>-->
<!--         </div>-->
<!--        <div class="foot_text">我们非常荣幸地宣布木木美术馆将与英国大英博物馆联合举办“意大利文艺复兴纸上绘画：一次与中国的对话”展览，这标志着大英博物馆与中国民营美术馆的首次合作。</div>-->
<!--        <div class="foot_text">此次展览旨在将意大利文艺复兴时期的纸上绘画介绍给中国的观众，呈现大英博物馆藏品中包括列奥纳多·达·芬奇、米开朗基罗、提香和拉斐尔在内的众多文艺复兴伟大艺术家的作品。本次展览是历史上首次将这些作品与中国当代艺术并置对话，发掘西方文艺复兴与中国之间跨越时空的重要关联。</div>-->
            <div class="foot_text" v-html=info.value.content></div>
    </main>
  </div>
</template>

<script setup>
import {reactive} from "vue";
import {getActivityDetail, imgUrl} from "../../api";
const info = reactive({})
// const activities = [
//   {
//     content: '策展人',
//     timestamp: '王宗孚（木木美术馆）、莎拉·沃尔斯（Sarah Vowles）（大英博物馆）',
//   },
//   {
//     content: '大英博物馆藏展品',
//     timestamp: '列奥纳多·达·芬奇、米开朗基罗、提香、拉斐尔、波利多罗·达·卡拉瓦乔、洛伦佐·迪·克雷蒂等艺术家的作品',
//
//   },
//   {
//     content: '对话艺术家”',
//     timestamp: '郝量、胡晓媛、靳尚谊、阚萱、刘小东、刘野、仇晓飞、谢南星、于吉、曾梵志',
//   }
// ]
const ActivityDetail = () => {
  let activityId=window.location.href.split('=')
  getActivityDetail({activityId:activityId[1]}).then(res=>{
    if(res.code==1){
      // console.log(res.data);
      info.value=res.data
    }
  })
}
ActivityDetail()

</script>

<style lang="scss" scoped>
.activity_boxs {
    font-family: PingFang SC;
    z-index: 1;
    max-width: 1920px;
    margin: 0 auto;
  .img_css {
    width: 100%;
    display: flex;
  }

  .activity_head {
    // border: 30px solid #ffda00;
  }

  .activity_main {
    padding: 59px 0;
    width: 1400px;
    margin: 0 auto;

    .details_head {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        color: #999999;

        .select_idx {
            width: 20px;
            height: 14px;
            display: flex;
            margin-right: 12px;
        }
    }

    .details_box {
        width: 1200px;
        margin: 58px auto 0;

        .details_title {
            text-align: center;
            font-size: 36px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #111111;
        }

        .details_introduce {
            margin-top: 39px;

            .details_text {
                float: left;
                font-size: 20px;
                font-weight: 300;
                color: #666666;
            }

            .details_time {
                float: right;
                font-size: 16px;
                font-weight: 300;
                color: #666666;
            }
        }

        .details_png {
            margin-top: 60px;
            width: 100%;
            // height: 608px;
        }

        .details_title1 {
            margin-top: 39px;
            text-align: center;
            font-size: 20px;
            font-weight: 300;
            color: #333333;
            line-height: 33px;
        }
    }

    .time_line {
        margin-top: 37px;
        .item_line_text {

        }
    }

    .foot_text {
        width: 1200px;
        margin: 60px auto 0;
        font-size: 20px;
        font-weight: 300;
    }
  }

    ::v-deep .el-timeline-item__node--normal {
        background: #FFDA00 !important;
    }

    ::v-deep .el-timeline-item__tail {
        border-left: 2px dashed #FFDA00;
    }

    ::v-deep .el-timeline-item {
        height: 69px;
    }
}
</style>
<style lang="scss">
.foot_text{
  img{
    width: 100%;
    height: 100%;
  }
}
</style>
